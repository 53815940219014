import { useRouter } from "vue-router";
import getUserAuthenticated from "@/shared/helpers/getUserAuthenticated";
const useAuthenticated = () => {
  const router = useRouter();
  const routeName = router.currentRoute.value.name ?? "login";

  let { isLogged, role } = getUserAuthenticated();
  if (isLogged) {
    if (role === "coordinator") role = "admin";
    const routesPublic = ["login", "forgot-password"];

    /* validate role */
    if (!routeName.startsWith(role) || routesPublic.includes(role)) {
      router.push({ name: role + "-dashboard" });
    } else {
      router.push({ name: routeName });
    }
  } else {
    router.push({ name: routeName });
  }
};

export default useAuthenticated;
