<template>
  <div class="layout-public d-flex align-items-center justify-content-center">
    <div
      class="container-form bg-primary-5 p-5 border-r-15 shadow"
      v-if="!isTokenValidateLoading || currentScreen !== 'create-password'"
    >
      <header class="logo mb-4 text-center">
        <img src="/assets/images/logotipo.png" alt="Bright Lingua" />
      </header>
      <router-view />
    </div>
    <div
      v-if="isTokenValidateLoading && currentScreen === 'create-password'"
      class="text-white text-center container-loader"
    >
      <img src="/assets/images/loader.gif" alt="Loader of Bright Lingua" />
      <p class="mt-3 fs-5">ONE MOMENT, PLEASE</p>
      <h2 class="fw-bold mt-3">We are validating your solicitude</h2>
    </div>
  </div>
</template>

<style lang="scss" src="@/shared/styles/layouts/layout-public.scss"></style>
<script>
import usePublicLayout from "@/shared/composables/Layouts/usePublicLayout";
import useResetPassword from "@/modules/public/composables/useResetPassword";

export default {
  name: "PublicLayout",
  components: {},
  setup() {
    const { isTokenValidateLoading, currentScreen } = useResetPassword();

    return {
      ...usePublicLayout(),
      currentScreen,
      isTokenValidateLoading,
    };
  },
};
</script>