import { onBeforeMount } from "vue";
import useAuthenticated from "../useAuthenticated";

const usePublicLayout = () => {
  onBeforeMount(() => {
    useAuthenticated();
  });
};

export default usePublicLayout;
